import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='166.24' y='247.41' width='0.75' height='3.38' />
			<rect x='145.54' y='247.41' width='20.7' height='3.38' />
			<rect x='144.04' y='247.41' width='1.5' height='3.38' />
			<rect x='118.96' y='247.41' width='25.07' height='3.38' />
			<rect x='117.46' y='247.41' width='1.5' height='3.38' />
			<rect x='95.34' y='247.41' width='22.12' height='3.38' />
			<rect x='93.84' y='247.41' width='1.5' height='3.38' />
			<rect x='71.72' y='247.41' width='22.12' height='3.38' />
			<rect x='70.22' y='247.41' width='1.5' height='3.38' />
			<rect x='45.14' y='247.41' width='25.07' height='3.38' />
			<rect x='43.64' y='247.41' width='1.5' height='3.38' />
			<rect x='23' y='247.41' width='20.65' height='3.38' />
			<rect x='21.5' y='247.41' width='1.5' height='3.38' />
			<rect x='4.4' y='247.41' width='17.1' height='3.38' />
			<rect x='0.25' y='216.84' width='3.4' height='29.97' />
			<rect x='0.25' y='215.34' width='3.4' height='1.5' />
			<rect x='0.25' y='184.36' width='3.4' height='30.98' />
			<rect x='0.25' y='182.86' width='3.4' height='1.5' />
			<rect x='0.25' y='151.88' width='3.4' height='30.98' />
			<rect x='0.25' y='151.13' width='3.4' height='0.75' />
			<rect x='190.13' y='245.11' width='3.43' height='3.38' />
			<rect x='190.13' y='215.33' width='3.43' height='0.75' />
			<rect x='190.13' y='189.08' width='3.43' height='26.25' />
			<rect x='190.13' y='188.33' width='3.43' height='0.75' />
			<rect x='193.57' y='184.96' width='0.75' height='3.38' />
			<rect x='190.13' y='184.96' width='3.43' height='3.38' />
			<rect x='194.32' y='184.96' width='19.55' height='3.38' />
			<rect x='213.87' y='184.96' width='1.5' height='3.38' />
			<rect x='215.37' y='184.96' width='25.07' height='3.38' />
			<rect x='240.44' y='184.96' width='1.5' height='3.38' />
			<rect x='241.94' y='184.96' width='20.56' height='3.38' />
			<rect x='262.5' y='184.96' width='0.75' height='3.38' />
			<rect x='263.25' y='184.96' width='3.46' height='4.12' />
			<rect x='263.25' y='189.08' width='3.46' height='25.59' />
			<rect x='263.25' y='214.67' width='3.46' height='1.52' />
			<rect x='263.25' y='216.19' width='3.46' height='28.17' />
			<rect x='263.25' y='244.36' width='3.46' height='4.12' />
			<polygon
				points='264.21,259.79 264.21,276.99 192.64,276.99 192.64,268.94 191.54,268.94 191.52,278.11 
	265.33,278.11 265.33,259.79'
			/>
			<line x1='166.24' y1='249.1' x2='145.54' y2='249.1' />
			<line x1='144.04' y1='249.1' x2='118.96' y2='249.1' />
			<line x1='70.22' y1='249.1' x2='45.14' y2='249.1' />
			<line x1='43.64' y1='249.1' x2='23' y2='249.1' />
			<line x1='262.5' y1='186.65' x2='241.94' y2='186.65' />
			<line x1='240.44' y1='186.65' x2='215.37' y2='186.65' />
			<line x1='191.85' y1='215.33' x2='191.85' y2='189.08' />
			<rect x='29.95' y='151.42' width='18.76' height='1.48' />
			<rect x='39.34' y='152.9' width='18.76' height='1.48' />
			<rect x='196.74' y='89.95' width='18.76' height='1.48' />
			<rect x='206.12' y='91.42' width='18.76' height='1.48' />
			<rect x='327.92' y='6.97' width='24' height='45' />
			<path
				d='M349.54,28.4l-2.07-17.19c-0.14-1.13-1.1-1.98-2.23-1.98h-10.63c-1.14,0-2.1,0.85-2.23,1.98L330.3,28.4
	c-0.09,0.71-0.09,1.44,0,2.15l2.07,17.19c0.14,1.13,1.1,1.98,2.23,1.98h10.63c1.14,0,2.1-0.85,2.23-1.98l2.07-17.19
	C349.63,29.84,349.63,29.11,349.54,28.4z'
			/>
			<rect x='265.85' y='72.64' width='1.33' height='25.51' />
			<rect x='97.69' y='135.73' width='25.51' height='1.33' />
			<rect x='272.87' y='122.78' width='25.51' height='1.33' />
			<rect x='272.87' y='48.52' width='25.51' height='1.33' />

			<rect
				x='233.16'
				y='7.13'
				transform='matrix(0.7071 -0.7071 0.7071 0.7071 53.8954 171.3829)'
				width='1.33'
				height='27.02'
			/>
			<polyline points='327.92,12.77 303.95,12.77 303.95,23.85 279.95,23.85 279.95,6.97' />
			<polyline points='317.85,129.32 301,129.32 301,105.32 312.08,105.32 312.08,81.38 317.85,81.38' />
			<polyline points='97.72,129.89 115.72,129.89 115.72,45.32 144.23,45.32 144.23,27.32' />
			<line x1='144.98' y1='45.32' x2='165.98' y2='45.32' />
			<rect x='144.23' y='27.32' width='0.75' height='18' />
			<rect x='165.98' y='27.32' width='0.75' height='18' />

			<rect
				x='182.65'
				y='31.69'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 94.3978 208.8929)'
				width='1.33'
				height='22.5'
			/>
			<rect x='173.31' y='13.86' width='20.44' height='22.69' />
			<rect x='161.12' y='244.36' width='4.5' height='0.75' />
			<rect x='185.64' y='244.36' width='4.49' height='0.75' />

			<rect
				x='225.05'
				y='13.6'
				transform='matrix(0.8765 -0.4813 0.4813 0.8765 16.9972 111.4274)'
				width='1.33'
				height='17.96'
			/>
			<line x1='283.82' y1='22.75' x2='283.82' y2='10.75' />
			<line x1='292.3' y1='13.51' x2='291.54' y2='13.51' />
			<path d='M291.35,12.2c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='292.3' y1='12.39' x2='291.54' y2='12.39' />
			<path d='M292.3,12.39c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='291.35' y1='13.32' x2='291.35' y2='9.1' />
			<path d='M291.35,13.32c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='292.3' y1='13.51' x2='291.54' y2='13.51' />
			<path d='M292.3,13.51c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='292.49' y1='9.1' x2='292.49' y2='13.32' />
			<path d='M291.49,8.95c-0.08,0-0.14,0.06-0.14,0.14' />
			<line x1='292.35' y1='8.95' x2='291.49' y2='8.95' />
			<path d='M292.49,9.1c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='300.02' y1='10.75' x2='292.49' y2='10.75' />
			<line x1='300.02' y1='22.75' x2='300.02' y2='10.75' />
			<line x1='283.82' y1='22.75' x2='300.02' y2='22.75' />
			<line x1='291.35' y1='10.75' x2='283.82' y2='10.75' />
			<path
				d='M309.77,21.48c0,0.67,0.07,1.06,0.14,1.48c0.09,0.54,0.2,1.12,0.4,1.65c0.21,0.55,0.53,1.04,0.86,1.43
	c0.25,0.3,0.5,0.55,0.73,0.73c0.19,0.15,0.36,0.24,0.6,0.35c0.23,0.1,0.51,0.2,0.8,0.28c0.31,0.08,0.62,0.14,0.91,0.17
	c0.3,0.03,0.57,0.03,0.91,0.03c0.32,0,0.7,0,1.04-0.03c0.32-0.03,0.61-0.08,0.87-0.12c0.22-0.04,0.43-0.08,0.63-0.15
	c0.21-0.08,0.41-0.19,0.61-0.32c0.29-0.18,0.58-0.38,0.89-0.71c0.33-0.35,0.69-0.84,0.92-1.26c0.22-0.41,0.32-0.76,0.41-1.1
	c0.08-0.29,0.15-0.56,0.23-1.04c0.07-0.39,0.13-0.92,0.13-1.4'
			/>
			<line x1='320.83' y1='21.48' x2='320.83' y2='12.77' />
			<line x1='309.77' y1='21.48' x2='309.77' y2='12.77' />
			<line x1='302.1' y1='125.45' x2='314.1' y2='125.45' />
			<line x1='311.34' y1='116.97' x2='311.34' y2='117.74' />
			<path d='M312.46,117.74c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='312.46' y1='116.97' x2='312.46' y2='117.74' />
			<path d='M312.64,116.78c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='311.53' y1='117.92' x2='315.75' y2='117.92' />
			<path d='M311.34,117.74c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='311.34' y1='116.97' x2='311.34' y2='117.74' />
			<path d='M311.53,116.78c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='315.75' y1='116.78' x2='311.53' y2='116.78' />
			<path d='M315.75,117.92c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='315.89' y1='116.93' x2='315.89' y2='117.78' />
			<path d='M315.89,116.93c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='314.1' y1='109.26' x2='314.1' y2='116.78' />
			<line x1='302.1' y1='109.26' x2='314.1' y2='109.26' />
			<line x1='302.1' y1='125.45' x2='302.1' y2='109.26' />
			<line x1='314.1' y1='117.92' x2='314.1' y2='125.45' />
			<path
				d='M303.37,99.5c-0.67,0-1.06-0.07-1.48-0.14c-0.54-0.09-1.12-0.2-1.65-0.4c-0.55-0.21-1.04-0.53-1.43-0.86
	c-0.3-0.25-0.55-0.5-0.73-0.73c-0.15-0.19-0.24-0.36-0.35-0.6c-0.1-0.23-0.2-0.51-0.28-0.8c-0.08-0.31-0.14-0.62-0.17-0.91
	c-0.03-0.3-0.03-0.57-0.03-0.91c0-0.32,0-0.7,0.03-1.04c0.03-0.32,0.08-0.61,0.12-0.87c0.04-0.22,0.08-0.43,0.15-0.63
	c0.08-0.21,0.19-0.41,0.32-0.61c0.18-0.29,0.38-0.58,0.71-0.89c0.35-0.33,0.84-0.69,1.26-0.92c0.41-0.22,0.76-0.32,1.1-0.41
	c0.29-0.08,0.56-0.15,1.04-0.23c0.39-0.07,0.92-0.13,1.4-0.13'
			/>
			<line x1='303.37' y1='88.44' x2='312.08' y2='88.44' />
			<line x1='303.37' y1='99.5' x2='312.08' y2='99.5' />

			<rect
				x='285.4'
				y='70.91'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 133.0616 336.3469)'
				width='0.59'
				height='21.11'
			/>
			<rect x='272.87' y='78.43' width='2.55' height='0.59' />
			<rect x='296.54' y='78.43' width='21.31' height='0.59' />
			<line x1='289.47' y1='57.32' x2='301.28' y2='57.32' />
			<polygon points='3.65,246.81 0.25,246.81 0.25,250.78 4.4,250.78 4.4,247.41 3.65,247.41 3.65,246.81' />
			<rect x='115.72' y='111.14' width='0.38' height='18' />
			<path d='M116.1,128.13c1.95-5.15,1.95-10.84,0-15.99' />
			<line x1='28.3' y1='141.77' x2='67.6' y2='141.77' />
			<line x1='191.18' y1='81.64' x2='235.79' y2='81.64' />
			<line x1='211.99' y1='35.41' x2='211.99' y2='11.61' />
			<rect x='99.22' y='89.34' width='15.75' height='21' />
			<path d='M100.35,99.42c-0.23,0-0.42,0.19-0.42,0.42c0,0.23,0.19,0.42,0.42,0.42' />
			<path
				d='M100.86,99.42c-0.23-0.28-0.65-0.32-0.93-0.09c-0.28,0.23-0.32,0.65-0.09,0.93c0.23,0.28,0.65,0.32,0.93,0.09
	c0.03-0.03,0.06-0.06,0.09-0.09'
			/>
			<polyline points='100.35,100.26 100.35,100.26 105.72,100.26' />
			<line x1='105.72' y1='99.42' x2='100.35' y2='99.42' />
			<polyline points='99.69,101.9 101.01,101.9 101.02,100.26' />
			<line x1='99.69' y1='101.9' x2='99.69' y2='99.84' />
			<path d='M105.72,100.26c0.23,0,0.42-0.19,0.42-0.42c0-0.23-0.19-0.42-0.42-0.42' />
			<rect x='115.72' y='56.37' width='0.38' height='18' />
			<path d='M116.1,73.36c1.95-5.15,1.95-10.84,0-15.99' />
			<line x1='114.36' y1='54.12' x2='98.85' y2='54.12' />
			<line x1='114.36' y1='76.62' x2='98.85' y2='76.62' />
			<circle cx='113.07' cy='57.88' r='0.68' />
			<circle cx='113.07' cy='62.85' r='0.68' />
			<circle cx='113.07' cy='72.85' r='0.68' />
			<circle cx='113.07' cy='67.88' r='0.68' />
			<line x1='98.62' y1='76.38' x2='98.62' y2='54.35' />
			<line x1='114.6' y1='54.35' x2='114.6' y2='76.38' />
			<path d='M114.6,54.35c0-0.13-0.1-0.23-0.23-0.23' />
			<path d='M114.36,76.62c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M98.62,76.38c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M98.85,54.12c-0.13,0-0.23,0.1-0.23,0.23' />
		</g>
	);
};

export default Lineworks;
