import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Ensuite: IScene = {
	name: 'Ensuite',
	pan: '180deg',
	hotspot: {
		x: 280,
		y: 102,
	},
	images: {
		scheme1: Scheme1.Ensuite,
		scheme2: Scheme2.Ensuite,
	},
	default: {
		latitude: -0.09954017916129754,
		longitude: 1.5740452593865097,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6360937074488322,
				longitude: 4.582709432262936,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.09154178202344454,
					longitude: 4.469373643320113,
				},
			},
		},
	],
};

export default Ensuite;
