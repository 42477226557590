import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	pan: '180deg',
	hotspot: {
		x: 220,
		y: 100,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.04355139919633144,
		longitude: 3.1703289691597876,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6151507056055507,
				longitude: 1.712846824377198,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.09954017916129754,
					longitude: 1.5740452593865097,
				},
			},
		},
	],
};

export default MasterBedroom;
