import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '180deg',
	hotspot: {
		x: 155,
		y: 55,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.06645987556642163,
		longitude: 3.1365067304020022,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.36224419430473986,
				longitude: 1.7244584605076039,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.02868287789135815,
					longitude: 1.540592461030289,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2514964925976857,
				longitude: 3.1132171931478565,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.08761410088096344,
					longitude: 3.183206345251927,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.12382230220444757,
				longitude: 3.5845980372086093,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.0076094446625969425,
					longitude: 3.6519274875174417,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.27596132264381934,
				longitude: 1.4751303264270195,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.09842785171222701,
					longitude: 1.5670003574549238,
				},
			},
		},
	],
};

export default Kitchen;
