import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='339.92' cy='40.82' r='0.75' />
			<rect x='223.8' y='7.42' width='27.02' height='4.13' />
			<line x1='144.98' y1='43.44' x2='165.98' y2='43.44' />
			<line x1='173.31' y1='34.03' x2='193.75' y2='34.03' />
			<line x1='193.57' y1='245.11' x2='193.57' y2='216.08' />
			<line x1='190.13' y1='245.11' x2='190.19' y2='216.08' />
			<line x1='284.38' y1='22.11' x2='284.38' y2='11.31' />
			<path
				d='M291.35,13.04c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04c0.34-0.31,0.36-0.84,0.04-1.18
	c-0.01-0.01-0.03-0.03-0.04-0.04'
			/>
			<path d='M291.17,10.09c0,0.1,0.08,0.18,0.18,0.19' />
			<line x1='291.17' y1='8.96' x2='291.17' y2='10.09' />
			<path d='M291.36,8.77c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='291.78' y1='8.77' x2='291.36' y2='8.77' />
			<line x1='292.48' y1='8.77' x2='292.06' y2='8.77' />
			<path d='M292.67,8.96c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='292.67' y1='10.09' x2='292.67' y2='8.96' />
			<path d='M292.49,10.27c0.1,0,0.18-0.09,0.18-0.19' />
			<line x1='292.06' y1='8.4' x2='291.78' y2='8.4' />
			<line x1='292.06' y1='8.95' x2='292.06' y2='8.4' />
			<line x1='291.78' y1='8.4' x2='291.78' y2='8.95' />
			<line x1='299.45' y1='11.31' x2='292.49' y2='11.31' />
			<line x1='299.45' y1='22.11' x2='299.45' y2='11.31' />
			<line x1='284.38' y1='22.11' x2='299.45' y2='22.11' />
			<line x1='291.35' y1='11.31' x2='284.38' y2='11.31' />
			<path
				d='M310.14,15.39c0.03-0.41,0.07-0.57,0.11-0.71c0.04-0.13,0.08-0.24,0.14-0.35c0.06-0.11,0.12-0.23,0.19-0.32
	c0.07-0.1,0.13-0.18,0.2-0.25c0.08-0.08,0.16-0.15,0.26-0.22c0.12-0.08,0.26-0.17,0.4-0.24c0.16-0.08,0.32-0.14,0.47-0.19
	c0.16-0.05,0.31-0.09,0.47-0.12c0.18-0.04,0.38-0.07,0.57-0.09c0.24-0.03,0.47-0.05,0.71-0.06c0.31-0.02,0.62-0.03,0.97-0.03
	c0.43-0.01,0.91-0.01,1.41,0.01c0.47,0.01,0.95,0.04,1.34,0.08c0.32,0.03,0.56,0.07,0.85,0.14c0.19,0.05,0.4,0.11,0.57,0.18
	c0.15,0.05,0.27,0.11,0.39,0.17c0.11,0.06,0.21,0.13,0.33,0.23c0.12,0.1,0.25,0.23,0.35,0.36c0.1,0.12,0.18,0.24,0.24,0.35
	c0.06,0.09,0.11,0.18,0.15,0.28c0.04,0.09,0.07,0.19,0.09,0.3c0.02,0.1,0.04,0.19,0.06,0.31c0.02,0.12,0.04,0.27,0.04,0.65'
			/>
			<path
				d='M310.14,21.48c0,0.3,0.01,0.53,0.04,0.74c0.01,0.1,0.02,0.2,0.04,0.3c0.01,0.09,0.03,0.19,0.05,0.28
	c0.02,0.1,0.03,0.19,0.05,0.29c0.02,0.1,0.04,0.2,0.05,0.29c0.02,0.1,0.04,0.2,0.06,0.29s0.04,0.19,0.07,0.29
	c0.02,0.09,0.05,0.19,0.08,0.28c0.03,0.09,0.06,0.18,0.09,0.27c0.03,0.09,0.07,0.17,0.11,0.25c0.04,0.08,0.08,0.16,0.12,0.24
	c0.09,0.16,0.18,0.31,0.28,0.45c0.1,0.14,0.21,0.28,0.31,0.4s0.21,0.24,0.31,0.34s0.2,0.19,0.29,0.26
	c0.05,0.04,0.09,0.07,0.13,0.1s0.09,0.06,0.13,0.08c0.05,0.03,0.09,0.05,0.15,0.08s0.11,0.05,0.18,0.08
	c0.07,0.03,0.14,0.06,0.21,0.08c0.07,0.03,0.15,0.05,0.23,0.08c0.08,0.03,0.16,0.05,0.24,0.07s0.16,0.04,0.24,0.06
	c0.16,0.04,0.32,0.06,0.47,0.08c0.08,0.01,0.15,0.02,0.22,0.02c0.07,0.01,0.14,0.01,0.22,0.01c0.14,0.01,0.29,0.01,0.46,0.01
	c0.17,0,0.35,0,0.54,0c0.19,0,0.38-0.01,0.55-0.02c0.09-0.01,0.17-0.01,0.25-0.02s0.16-0.02,0.24-0.03
	c0.08-0.01,0.15-0.02,0.23-0.04c0.04-0.01,0.07-0.01,0.11-0.02c0.04-0.01,0.07-0.01,0.11-0.02c0.07-0.01,0.14-0.03,0.2-0.04
	c0.06-0.01,0.12-0.03,0.18-0.04c0.03-0.01,0.05-0.02,0.08-0.02c0.03-0.01,0.05-0.02,0.08-0.03c0.05-0.02,0.11-0.04,0.16-0.07
	s0.11-0.06,0.17-0.09c0.06-0.03,0.12-0.07,0.17-0.11c0.06-0.04,0.12-0.07,0.18-0.11c0.06-0.04,0.12-0.08,0.17-0.12
	c0.06-0.04,0.11-0.09,0.17-0.14c0.06-0.05,0.12-0.1,0.18-0.16s0.12-0.12,0.18-0.19c0.06-0.07,0.12-0.14,0.19-0.22
	c0.12-0.15,0.25-0.32,0.36-0.49c0.06-0.08,0.11-0.17,0.16-0.25s0.09-0.16,0.13-0.23c0.04-0.08,0.07-0.15,0.1-0.22
	s0.06-0.14,0.08-0.21c0.02-0.07,0.05-0.14,0.07-0.2s0.04-0.14,0.06-0.2c0.02-0.07,0.04-0.14,0.06-0.21
	c0.02-0.07,0.04-0.14,0.05-0.21c0.02-0.07,0.04-0.15,0.05-0.23c0.02-0.08,0.04-0.17,0.05-0.27c0.01-0.05,0.02-0.1,0.03-0.16
	s0.02-0.11,0.03-0.17c0.02-0.12,0.04-0.25,0.05-0.39c0.02-0.14,0.03-0.28,0.04-0.42c0.01-0.14,0.02-0.28,0.02-0.41'
			/>
			<line x1='310.14' y1='21.48' x2='310.14' y2='15.39' />
			<line x1='320.46' y1='21.48' x2='320.46' y2='15.85' />
			<line x1='302.74' y1='124.89' x2='313.54' y2='124.89' />
			<path
				d='M311.8,116.78c-0.31-0.34-0.84-0.36-1.18-0.04s-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04
	c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M314.57,117.92c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='315.88' y1='118.1' x2='314.76' y2='118.1' />
			<path d='M315.88,118.1c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='316.07' y1='117.5' x2='316.07' y2='117.92' />
			<line x1='316.07' y1='116.79' x2='316.07' y2='117.21' />
			<path d='M316.07,116.79c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='314.76' y1='116.6' x2='315.88' y2='116.6' />
			<path d='M314.76,116.6c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='316.45' y1='117.21' x2='316.45' y2='117.5' />
			<line x1='315.89' y1='117.21' x2='316.45' y2='117.21' />
			<line x1='316.45' y1='117.5' x2='315.89' y2='117.5' />
			<line x1='313.54' y1='109.82' x2='313.54' y2='116.78' />
			<line x1='302.74' y1='109.82' x2='313.54' y2='109.82' />
			<line x1='302.74' y1='124.89' x2='302.74' y2='109.82' />
			<line x1='313.54' y1='117.92' x2='313.54' y2='124.89' />
			<path
				d='M309.46,99.13c0.41-0.03,0.57-0.07,0.71-0.11c0.13-0.04,0.24-0.08,0.35-0.14c0.11-0.06,0.23-0.12,0.32-0.19
	c0.1-0.07,0.18-0.13,0.25-0.2c0.08-0.08,0.15-0.16,0.22-0.26c0.08-0.12,0.17-0.26,0.24-0.4c0.08-0.16,0.14-0.32,0.19-0.47
	c0.05-0.16,0.09-0.31,0.12-0.47c0.04-0.18,0.07-0.38,0.09-0.57c0.03-0.24,0.05-0.47,0.06-0.71c0.02-0.31,0.03-0.62,0.03-0.97
	c0.01-0.43,0.01-0.91-0.01-1.41c-0.01-0.47-0.04-0.95-0.08-1.34c-0.03-0.32-0.07-0.56-0.14-0.85c-0.05-0.19-0.11-0.4-0.18-0.57
	c-0.05-0.15-0.11-0.27-0.17-0.39c-0.06-0.11-0.13-0.21-0.23-0.33c-0.1-0.12-0.23-0.25-0.36-0.35c-0.12-0.1-0.24-0.18-0.35-0.24
	c-0.09-0.06-0.18-0.11-0.28-0.15c-0.09-0.04-0.19-0.07-0.3-0.09c-0.1-0.02-0.19-0.04-0.31-0.06c-0.12-0.02-0.27-0.04-0.65-0.04'
			/>
			<path
				d='M303.37,99.13c-0.3,0-0.53-0.01-0.74-0.04c-0.1-0.01-0.2-0.02-0.3-0.04c-0.09-0.01-0.19-0.03-0.28-0.05
	s-0.19-0.03-0.29-0.05c-0.1-0.02-0.2-0.04-0.29-0.05c-0.1-0.02-0.2-0.04-0.29-0.06s-0.19-0.04-0.29-0.07s-0.19-0.05-0.28-0.08
	c-0.09-0.03-0.18-0.06-0.27-0.09c-0.09-0.03-0.17-0.07-0.25-0.11c-0.08-0.04-0.16-0.08-0.24-0.12c-0.16-0.09-0.31-0.18-0.45-0.28
	c-0.14-0.1-0.28-0.21-0.4-0.31c-0.13-0.11-0.24-0.21-0.34-0.31c-0.1-0.1-0.19-0.2-0.26-0.29c-0.04-0.05-0.07-0.09-0.1-0.13
	c-0.03-0.04-0.06-0.09-0.08-0.13c-0.03-0.05-0.05-0.09-0.08-0.15c-0.03-0.05-0.05-0.11-0.08-0.18c-0.03-0.07-0.06-0.14-0.08-0.21
	c-0.03-0.07-0.05-0.15-0.08-0.23s-0.05-0.16-0.07-0.24s-0.04-0.16-0.06-0.24c-0.04-0.16-0.06-0.32-0.08-0.47
	c-0.01-0.08-0.02-0.15-0.02-0.22c-0.01-0.07-0.01-0.14-0.01-0.22c-0.01-0.14-0.01-0.29-0.01-0.46c0-0.17,0-0.35,0-0.54
	c0-0.19,0.01-0.38,0.02-0.55c0.01-0.09,0.01-0.17,0.02-0.25s0.02-0.16,0.03-0.24c0.01-0.08,0.02-0.15,0.04-0.23
	c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.07,0.03-0.14,0.04-0.2c0.01-0.06,0.03-0.12,0.04-0.18
	c0.01-0.03,0.02-0.05,0.02-0.08c0.01-0.03,0.02-0.05,0.03-0.08c0.02-0.05,0.04-0.11,0.07-0.16c0.03-0.05,0.06-0.11,0.09-0.17
	c0.03-0.06,0.07-0.12,0.11-0.17c0.04-0.06,0.07-0.12,0.11-0.18c0.04-0.06,0.08-0.12,0.12-0.17c0.04-0.06,0.09-0.11,0.14-0.17
	s0.1-0.12,0.16-0.18c0.06-0.06,0.12-0.12,0.19-0.18s0.14-0.12,0.22-0.19c0.15-0.12,0.32-0.25,0.49-0.36
	c0.08-0.06,0.17-0.11,0.25-0.16s0.16-0.09,0.23-0.13c0.08-0.04,0.15-0.07,0.22-0.1c0.07-0.03,0.14-0.06,0.21-0.08
	c0.07-0.02,0.14-0.05,0.2-0.07c0.07-0.02,0.14-0.04,0.2-0.06c0.07-0.02,0.14-0.04,0.21-0.06s0.14-0.04,0.21-0.05
	c0.07-0.02,0.15-0.04,0.23-0.05c0.08-0.02,0.17-0.04,0.27-0.05c0.05-0.01,0.1-0.02,0.16-0.03c0.05-0.01,0.11-0.02,0.17-0.03
	c0.12-0.02,0.25-0.04,0.39-0.05c0.14-0.02,0.28-0.03,0.42-0.04c0.14-0.01,0.28-0.02,0.41-0.02'
			/>
			<line x1='303.37' y1='99.13' x2='309.46' y2='99.13' />
			<line x1='303.37' y1='88.81' x2='308.99' y2='88.81' />
			<rect x='273.21' y='57.69' width='44.29' height='20.74' />
			<line x1='317.5' y1='78.43' x2='303.6' y2='68.72' />
			<line x1='303.61' y1='67.05' x2='317.5' y2='57.69' />
			<line x1='301' y1='67.39' x2='273.21' y2='57.69' />
			<line x1='273.21' y1='78.43' x2='301' y2='68.38' />
			<circle cx='302.39' cy='67.88' r='1.48' />
			<line x1='272.78' y1='81.38' x2='284.24' y2='81.38' />
			<line x1='286.52' y1='81.38' x2='312.08' y2='81.38' />
			<path d='M116.1,126.37c1.2-4.07,1.2-8.39,0-12.46' />
			<circle cx='100.35' cy='101.62' r='0.19' />
			<line x1='99.69' y1='101.05' x2='101.02' y2='101.05' />
			<polyline points='102.22,99.42 102.22,90.84 113.47,90.84 113.47,108.84 102.22,108.84 102.22,100.26' />
			<path
				d='M106.03,100.13c0.16,0.38,0.6,0.57,0.98,0.41c0.38-0.16,0.57-0.6,0.41-0.98c-0.16-0.38-0.6-0.57-0.98-0.41
	c-0.18,0.08-0.33,0.22-0.41,0.41'
			/>
			<path d='M116.1,71.6c1.2-4.07,1.2-8.39,0-12.46' />
			<path
				d='M113.47,57.68c-0.11-0.22-0.38-0.31-0.6-0.2s-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
	c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='113.72' y1='57.68' x2='112.66' y2='57.68' />
			<line x1='113.72' y1='58.08' x2='112.66' y2='58.08' />
			<path
				d='M113.47,62.65c-0.11-0.22-0.38-0.31-0.6-0.2s-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
	c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='113.72' y1='62.65' x2='112.66' y2='62.65' />
			<line x1='113.72' y1='63.06' x2='112.66' y2='63.06' />
			<path
				d='M113.47,72.65c-0.11-0.22-0.38-0.31-0.6-0.2s-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
	c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='113.72' y1='73.05' x2='112.66' y2='73.05' />
			<line x1='113.72' y1='72.65' x2='112.66' y2='72.65' />
			<path
				d='M113.47,67.68c-0.11-0.22-0.38-0.31-0.6-0.2s-0.31,0.38-0.2,0.6c0.11,0.22,0.38,0.31,0.6,0.2
	c0.09-0.04,0.16-0.11,0.2-0.2'
			/>
			<line x1='113.72' y1='68.08' x2='112.66' y2='68.08' />
			<line x1='113.72' y1='67.68' x2='112.66' y2='67.68' />
			<line x1='111.35' y1='76.62' x2='111.35' y2='54.12' />
			<line x1='110.83' y1='76.62' x2='110.83' y2='71.3' />
			<line x1='110.83' y1='71.06' x2='110.83' y2='54.12' />
			<line x1='99.14' y1='76.62' x2='99.14' y2='71.3' />
			<line x1='99.14' y1='71.06' x2='99.14' y2='59.67' />
			<line x1='99.14' y1='59.43' x2='99.14' y2='54.12' />
			<line x1='110.25' y1='76.62' x2='110.25' y2='71.3' />
			<line x1='110.25' y1='71.06' x2='110.25' y2='59.67' />
			<line x1='110.25' y1='59.43' x2='110.25' y2='54.12' />
			<line x1='109.97' y1='76.62' x2='109.97' y2='71.3' />
			<line x1='109.97' y1='71.06' x2='109.97' y2='59.67' />
			<line x1='109.97' y1='59.43' x2='109.97' y2='54.12' />
			<line x1='100.27' y1='69.8' x2='100.27' y2='60.93' />
			<line x1='100.27' y1='58.17' x2='100.27' y2='54.12' />
			<line x1='100.27' y1='72.56' x2='100.27' y2='76.62' />
			<line x1='99.99' y1='69.81' x2='99.99' y2='60.92' />
			<line x1='99.99' y1='58.18' x2='99.99' y2='54.12' />
			<line x1='99.99' y1='72.55' x2='99.99' y2='76.62' />
			<line x1='101.7' y1='69.7' x2='101.7' y2='61.04' />
			<line x1='101.7' y1='58.07' x2='101.7' y2='54.12' />
			<line x1='101.7' y1='72.66' x2='101.7' y2='76.62' />
			<line x1='101.42' y1='69.7' x2='101.42' y2='61.04' />
			<line x1='101.42' y1='58.07' x2='101.42' y2='54.12' />
			<line x1='101.42' y1='72.66' x2='101.42' y2='76.62' />
			<line x1='102.85' y1='69.79' x2='102.85' y2='60.94' />
			<line x1='102.85' y1='58.16' x2='102.85' y2='54.12' />
			<line x1='102.85' y1='72.57' x2='102.85' y2='76.62' />
			<line x1='103.14' y1='69.8' x2='103.14' y2='60.94' />
			<line x1='103.14' y1='58.17' x2='103.14' y2='54.12' />
			<line x1='103.14' y1='72.56' x2='103.14' y2='76.62' />
			<line x1='106.02' y1='69.8' x2='106.02' y2='60.93' />
			<line x1='106.02' y1='58.17' x2='106.02' y2='54.12' />
			<line x1='106.02' y1='72.56' x2='106.02' y2='76.62' />
			<line x1='105.73' y1='69.81' x2='105.73' y2='60.92' />
			<line x1='105.73' y1='58.18' x2='105.73' y2='54.12' />
			<line x1='105.73' y1='72.55' x2='105.73' y2='76.62' />
			<line x1='107.45' y1='69.7' x2='107.45' y2='61.04' />
			<line x1='107.45' y1='58.07' x2='107.45' y2='54.12' />
			<line x1='107.45' y1='72.66' x2='107.45' y2='76.62' />
			<line x1='107.16' y1='69.7' x2='107.16' y2='61.04' />
			<line x1='107.16' y1='58.07' x2='107.16' y2='54.12' />
			<line x1='107.16' y1='72.66' x2='107.16' y2='76.62' />
			<line x1='108.59' y1='69.79' x2='108.59' y2='60.94' />
			<line x1='108.59' y1='58.16' x2='108.59' y2='54.12' />
			<line x1='108.59' y1='72.57' x2='108.59' y2='76.62' />
			<line x1='108.88' y1='69.8' x2='108.88' y2='60.94' />
			<line x1='108.88' y1='58.17' x2='108.88' y2='54.12' />
			<line x1='108.88' y1='72.56' x2='108.88' y2='76.62' />
			<line x1='104.35' y1='76.62' x2='104.35' y2='71.3' />
			<line x1='104.35' y1='71.06' x2='104.35' y2='59.67' />
			<line x1='104.35' y1='59.43' x2='104.35' y2='54.12' />
			<line x1='104.64' y1='76.62' x2='104.64' y2='71.3' />
			<line x1='104.64' y1='71.06' x2='104.64' y2='59.67' />
			<line x1='104.64' y1='59.43' x2='104.64' y2='54.12' />
			<line x1='111.35' y1='65.37' x2='98.62' y2='65.37' />
			<line x1='110.83' y1='65.8' x2='110.25' y2='65.8' />
			<line x1='109.97' y1='65.8' x2='108.88' y2='65.8' />
			<line x1='108.59' y1='65.8' x2='107.45' y2='65.8' />
			<line x1='107.16' y1='65.8' x2='106.02' y2='65.8' />
			<line x1='105.73' y1='65.8' x2='104.64' y2='65.8' />
			<line x1='104.35' y1='65.8' x2='103.14' y2='65.8' />
			<line x1='102.85' y1='65.8' x2='101.7' y2='65.8' />
			<line x1='101.42' y1='65.8' x2='100.27' y2='65.8' />
			<line x1='99.99' y1='65.8' x2='99.14' y2='65.8' />
			<line x1='110.83' y1='64.94' x2='110.25' y2='64.94' />
			<line x1='109.97' y1='64.94' x2='108.88' y2='64.94' />
			<line x1='108.59' y1='64.94' x2='107.45' y2='64.94' />
			<line x1='107.16' y1='64.94' x2='106.02' y2='64.94' />
			<line x1='105.73' y1='64.94' x2='104.64' y2='64.94' />
			<line x1='104.35' y1='64.94' x2='103.14' y2='64.94' />
			<line x1='102.85' y1='64.94' x2='101.7' y2='64.94' />
			<line x1='101.42' y1='64.94' x2='100.27' y2='64.94' />
			<line x1='99.99' y1='64.94' x2='99.14' y2='64.94' />
			<line x1='99.14' y1='76.18' x2='99.99' y2='76.18' />
			<line x1='100.27' y1='76.18' x2='101.42' y2='76.18' />
			<line x1='101.7' y1='76.18' x2='102.85' y2='76.18' />
			<line x1='103.14' y1='76.18' x2='104.35' y2='76.18' />
			<line x1='104.64' y1='76.18' x2='105.73' y2='76.18' />
			<line x1='106.02' y1='76.18' x2='107.16' y2='76.18' />
			<line x1='107.45' y1='76.18' x2='108.59' y2='76.18' />
			<line x1='108.88' y1='76.18' x2='109.97' y2='76.18' />
			<line x1='110.25' y1='76.18' x2='110.83' y2='76.18' />
			<line x1='110.83' y1='54.55' x2='110.25' y2='54.55' />
			<line x1='109.97' y1='54.55' x2='108.88' y2='54.55' />
			<line x1='108.59' y1='54.55' x2='107.45' y2='54.55' />
			<line x1='107.16' y1='54.55' x2='106.02' y2='54.55' />
			<line x1='105.73' y1='54.55' x2='104.64' y2='54.55' />
			<line x1='104.35' y1='54.55' x2='103.14' y2='54.55' />
			<line x1='102.85' y1='54.55' x2='101.7' y2='54.55' />
			<line x1='101.42' y1='54.55' x2='100.27' y2='54.55' />
			<line x1='99.99' y1='54.55' x2='99.14' y2='54.55' />
			<line x1='99.14' y1='59.67' x2='100.08' y2='59.67' />
			<line x1='103.04' y1='59.67' x2='104.35' y2='59.67' />
			<line x1='104.64' y1='59.67' x2='105.83' y2='59.67' />
			<line x1='108.79' y1='59.67' x2='109.97' y2='59.67' />
			<line x1='110.25' y1='59.67' x2='110.83' y2='59.67' />
			<line x1='99.14' y1='59.43' x2='100.08' y2='59.43' />
			<line x1='103.04' y1='59.43' x2='104.35' y2='59.43' />
			<line x1='104.64' y1='59.43' x2='105.83' y2='59.43' />
			<line x1='108.79' y1='59.43' x2='109.97' y2='59.43' />
			<line x1='110.25' y1='59.43' x2='110.83' y2='59.43' />
			<line x1='108.79' y1='71.06' x2='109.97' y2='71.06' />
			<line x1='110.25' y1='71.06' x2='110.83' y2='71.06' />
			<line x1='105.83' y1='71.06' x2='104.64' y2='71.06' />
			<line x1='104.35' y1='71.06' x2='103.04' y2='71.06' />
			<line x1='100.08' y1='71.06' x2='99.14' y2='71.06' />
			<line x1='108.79' y1='71.3' x2='109.97' y2='71.3' />
			<line x1='110.25' y1='71.3' x2='110.83' y2='71.3' />
			<line x1='105.83' y1='71.3' x2='104.64' y2='71.3' />
			<line x1='104.35' y1='71.3' x2='103.04' y2='71.3' />
			<line x1='100.08' y1='71.3' x2='99.14' y2='71.3' />
			<circle cx='101.56' cy='59.55' r='0.86' />
			<path d='M100.62,58.79c-0.36,0.44-0.36,1.08,0,1.52' />
			<path d='M102.35,58.64c-0.46-0.4-1.14-0.39-1.59,0.01' />
			<path d='M102.5,60.32c0.36-0.45,0.36-1.09,0-1.53' />
			<path d='M100.76,60.46c0.45,0.4,1.13,0.4,1.59,0.01' />
			<circle cx='101.56' cy='71.18' r='0.75' />
			<path d='M100.8,72.05c0.43,0.38,1.08,0.38,1.52,0.01' />
			<path d='M100.66,70.46c-0.34,0.42-0.34,1.02,0,1.45' />
			<path d='M102.32,70.3c-0.44-0.38-1.08-0.37-1.52,0.01' />
			<path d='M102.46,71.91c0.35-0.43,0.35-1.03,0-1.46' />
			<path d='M108.39,72.09c0.44-0.52,0.44-1.29,0-1.81' />
			<path d='M106.36,72.23c0.53,0.48,1.35,0.48,1.88,0.01' />
			<path d='M106.22,70.28c-0.43,0.52-0.43,1.28,0,1.8' />
			<path d='M108.25,70.13c-0.54-0.48-1.35-0.48-1.88,0.01' />
			<path d='M108.67,72.36c0.26-0.3,0.41-0.67,0.44-1.06' />
			<path d='M107.45,72.98c0.4-0.03,0.78-0.2,1.08-0.47' />
			<path d='M106.08,72.5c0.3,0.28,0.68,0.45,1.09,0.48' />
			<path d='M105.5,71.3c0.03,0.39,0.18,0.76,0.43,1.06' />
			<path d='M105.94,70c-0.25,0.3-0.41,0.67-0.43,1.06' />
			<path d='M107.16,69.38c-0.41,0.03-0.79,0.2-1.09,0.48' />
			<path d='M108.53,69.85c-0.3-0.27-0.68-0.44-1.08-0.47' />
			<path d='M109.11,71.06c-0.03-0.39-0.18-0.76-0.44-1.06' />
			<path d='M106.14,58.57c-0.2,0.24-0.33,0.54-0.35,0.86' />
			<path d='M107.16,58.03c-0.33,0.03-0.64,0.17-0.88,0.39' />
			<path d='M108.33,58.42c-0.24-0.22-0.55-0.35-0.88-0.39' />
			<path d='M108.83,59.43c-0.03-0.32-0.15-0.62-0.36-0.87' />
			<path d='M108.47,60.54c0.21-0.24,0.33-0.55,0.36-0.87' />
			<path d='M107.45,61.07c0.33-0.03,0.63-0.17,0.88-0.39' />
			<path d='M106.28,60.68c0.24,0.22,0.55,0.36,0.88,0.39' />
			<path d='M105.79,59.67c0.02,0.32,0.15,0.62,0.35,0.86' />
			<path d='M105.9,58.35c-0.26,0.3-0.42,0.69-0.44,1.09' />
			<path d='M107.16,57.71c-0.42,0.03-0.81,0.21-1.12,0.49' />
			<path d='M108.56,58.19c-0.31-0.28-0.7-0.45-1.11-0.49' />
			<path d='M109.15,59.43c-0.03-0.4-0.18-0.79-0.45-1.09' />
			<path d='M108.7,60.76c0.26-0.31,0.42-0.69,0.45-1.09' />
			<path d='M107.45,61.4c0.41-0.03,0.81-0.2,1.11-0.49' />
			<path d='M106.05,60.9c0.31,0.29,0.7,0.46,1.12,0.49' />
			<path d='M105.46,59.67c0.03,0.4,0.18,0.78,0.44,1.09' />
			<line x1='108.12' y1='71.83' x2='108.88' y2='72.56' />
			<line x1='106.62' y1='70.39' x2='106.02' y2='69.8' />
			<line x1='107.98' y1='71.98' x2='108.59' y2='72.57' />
			<line x1='106.48' y1='70.53' x2='105.73' y2='69.81' />
			<line x1='108.12' y1='70.53' x2='108.88' y2='69.8' />
			<line x1='106.62' y1='71.98' x2='106.02' y2='72.56' />
			<line x1='107.98' y1='70.38' x2='108.59' y2='69.79' />
			<line x1='106.48' y1='71.83' x2='105.73' y2='72.55' />
			<line x1='107.16' y1='69.7' x2='107.45' y2='69.7' />
			<line x1='107.16' y1='72.66' x2='107.45' y2='72.66' />
			<line x1='106.62' y1='70.39' x2='106.48' y2='70.53' />
			<line x1='106.62' y1='71.98' x2='106.48' y2='71.83' />
			<line x1='107.98' y1='70.38' x2='108.12' y2='70.53' />
			<line x1='107.98' y1='71.98' x2='108.12' y2='71.83' />
			<line x1='105.83' y1='71.06' x2='105.83' y2='71.3' />
			<line x1='108.79' y1='71.06' x2='108.79' y2='71.3' />
			<line x1='102.38' y1='71.83' x2='103.14' y2='72.56' />
			<line x1='100.88' y1='70.39' x2='100.27' y2='69.8' />
			<line x1='102.24' y1='71.98' x2='102.85' y2='72.57' />
			<line x1='100.74' y1='70.53' x2='99.99' y2='69.81' />
			<line x1='102.38' y1='70.53' x2='103.14' y2='69.8' />
			<line x1='100.88' y1='71.98' x2='100.27' y2='72.56' />
			<line x1='102.24' y1='70.38' x2='102.85' y2='69.79' />
			<line x1='100.74' y1='71.83' x2='99.99' y2='72.55' />
			<line x1='101.42' y1='69.7' x2='101.7' y2='69.7' />
			<line x1='101.42' y1='72.66' x2='101.7' y2='72.66' />
			<line x1='100.88' y1='70.39' x2='100.74' y2='70.53' />
			<line x1='100.88' y1='71.98' x2='100.74' y2='71.83' />
			<line x1='102.24' y1='70.38' x2='102.38' y2='70.53' />
			<line x1='102.24' y1='71.98' x2='102.38' y2='71.83' />
			<line x1='100.08' y1='71.06' x2='100.08' y2='71.3' />
			<line x1='103.04' y1='71.06' x2='103.04' y2='71.3' />
			<line x1='102.38' y1='60.21' x2='103.14' y2='60.94' />
			<line x1='100.88' y1='58.76' x2='100.27' y2='58.17' />
			<line x1='102.24' y1='60.35' x2='102.85' y2='60.94' />
			<line x1='100.74' y1='58.9' x2='99.99' y2='58.18' />
			<line x1='102.38' y1='58.9' x2='103.14' y2='58.17' />
			<line x1='100.88' y1='60.35' x2='100.27' y2='60.93' />
			<line x1='102.24' y1='58.75' x2='102.85' y2='58.16' />
			<line x1='100.74' y1='60.2' x2='99.99' y2='60.92' />
			<line x1='101.42' y1='58.07' x2='101.7' y2='58.07' />
			<line x1='101.42' y1='61.04' x2='101.7' y2='61.04' />
			<line x1='100.88' y1='58.76' x2='100.74' y2='58.9' />
			<line x1='100.88' y1='60.35' x2='100.74' y2='60.2' />
			<line x1='102.24' y1='58.75' x2='102.38' y2='58.9' />
			<line x1='102.24' y1='60.35' x2='102.38' y2='60.21' />
			<line x1='100.08' y1='59.43' x2='100.08' y2='59.67' />
			<line x1='103.04' y1='59.43' x2='103.04' y2='59.67' />
			<line x1='108.12' y1='60.21' x2='108.88' y2='60.94' />
			<line x1='106.62' y1='58.76' x2='106.02' y2='58.17' />
			<line x1='107.98' y1='60.35' x2='108.59' y2='60.94' />
			<line x1='106.48' y1='58.9' x2='105.73' y2='58.18' />
			<line x1='108.12' y1='58.9' x2='108.88' y2='58.17' />
			<line x1='106.62' y1='60.35' x2='106.02' y2='60.93' />
			<line x1='107.98' y1='58.75' x2='108.59' y2='58.16' />
			<line x1='106.48' y1='60.2' x2='105.73' y2='60.92' />
			<line x1='107.16' y1='58.07' x2='107.45' y2='58.07' />
			<line x1='107.16' y1='61.04' x2='107.45' y2='61.04' />
			<line x1='106.62' y1='58.76' x2='106.48' y2='58.9' />
			<line x1='106.62' y1='60.35' x2='106.48' y2='60.2' />
			<line x1='107.98' y1='58.75' x2='108.12' y2='58.9' />
			<line x1='107.98' y1='60.35' x2='108.12' y2='60.21' />
			<line x1='105.83' y1='59.43' x2='105.83' y2='59.67' />
			<line x1='108.79' y1='59.43' x2='108.79' y2='59.67' />
		</g>
	);
};

export default ThinLineworks;
