import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M241.64,72.64c0,13.59,10.64,24.8,24.21,25.51' />
			<path d='M97.69,161.27c13.59,0,24.8-10.64,25.51-24.21' />
			<path d='M298.38,122.78c-0.71-13.57-11.92-24.21-25.51-24.21' />
			<path d='M298.38,48.52c-0.71-13.57-11.92-24.21-25.51-24.21' />
			<path d='M243.82,29.69c4.5-4.97,6.99-11.44,7-18.14' />
			<path d='M194.01,46.49c0.51-1.9,0.77-3.86,0.77-5.82' />
			<rect x='165.62' y='244.36' width='20.02' height='0.75' />
			<path d='M190.13,216.08c-15.74,0-28.6,12.54-29.01,28.27' />
			<path d='M221.97,32.4c2.59,0,5.15-0.56,7.51-1.64' />
		</g>
	);
};

export default DoorSwings;
