import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='194.78' y1='38.92' x2='172.28' y2='38.92' />
			<line x1='271.13' y1='49.85' x2='271.13' y2='24.31' />
			<line x1='271.13' y1='98.57' x2='271.13' y2='124.11' />
			<line x1='266.71' y1='182.51' x2='214.32' y2='182.51' />
			<polyline points='190.13,216.08 187.68,216.08 187.68,188.33' />
			<line x1='166.99' y1='245.11' x2='118.21' y2='245.11' />
			<line x1='70.97' y1='245.11' x2='22.25' y2='245.11' />
		</g>
	);
};

export default index;
