import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='144.03' y='71.51' width='22.5' height='35.62' />
			<rect x='137.88' y='75.07' width='6.15' height='12' />
			<line x1='138.86' y1='87.07' x2='138.86' y2='75.07' />
			<rect x='137.88' y='91.57' width='6.15' height='12' />
			<line x1='138.86' y1='91.57' x2='138.86' y2='103.57' />
			<rect x='166.53' y='75.07' width='6.15' height='12' />
			<line x1='171.71' y1='87.07' x2='171.71' y2='75.07' />
			<rect x='166.53' y='91.57' width='6.15' height='12' />
			<line x1='171.71' y1='91.57' x2='171.71' y2='103.57' />
			<rect x='141.08' y='174.73' width='22.5' height='22.5' />
			<rect x='98.4' y='167.51' width='24' height='65.27' />
			<polyline points='120.15,232.78 120.15,229.78 101.41,229.78 101.41,170.51 120.15,170.51 120.15,167.51' />
			<line x1='122.4' y1='190.48' x2='101.41' y2='190.55' />
			<line x1='122.4' y1='209.8' x2='101.41' y2='209.8' />
			<rect x='102.01' y='171.18' width='4.5' height='57.93' />
			<rect x='177.53' y='167' width='9' height='45' />
			<rect x='181.32' y='171.5' width='1.12' height='36' />
			<polyline points='182.44,186.5 184.13,186.5 184.13,192.5 182.44,192.5' />
			<polyline points='181.32,192.5 179.63,192.5 179.63,186.5 181.32,186.5' />
			<rect x='137.6' y='140.9' width='25.98' height='24' />
			<polyline points='163.58,162.65 160.58,162.65 160.58,143.9 140.61,143.9 140.61,162.65 137.6,162.65' />
			<rect x='141.27' y='144.51' width='18.64' height='4.5' />
			<rect x='6.1' y='179.69' width='0.75' height='45.75' />
			<polyline points='20.7,225.07 66.1,225.07 66.1,180.07 38.03,180.07' />
			<polyline points='24.02,180.07 6.85,180.07 6.85,225.07 14.68,225.07' />
			<rect x='6.1' y='226.36' width='13.29' height='13.29' />
			<rect x='6.1' y='165.49' width='13.29' height='13.29' />
			<polyline points='15.38,221.69 8.27,221.69 8.27,204.07 19.04,204.07' />
			<polyline points='20.12,198.83 20.12,183.44 8.27,183.44 8.27,200.69 19.74,200.69' />
			<polygon points='14.6,225.44 20.55,225.44 38.18,179.69 24.1,179.69' />
			<rect x='192.16' y='119.7' width='0.75' height='45.75' />
			<polyline points='206.77,165.08 252.16,165.08 252.16,120.08 224.1,120.08' />
			<polyline points='210.09,120.08 192.91,120.08 192.91,165.08 200.74,165.08' />
			<rect x='192.16' y='166.37' width='13.29' height='13.29' />
			<rect x='192.16' y='105.5' width='13.29' height='13.29' />
			<polyline points='201.44,161.7 194.34,161.7 194.34,144.08 205.1,144.08' />
			<polyline points='206.19,138.84 206.19,123.45 194.34,123.45 194.34,140.7 205.8,140.7' />
			<polygon points='200.67,165.45 206.62,165.45 224.25,119.7 210.16,119.7' />
		</g>
	);
};

export default Furniture;
