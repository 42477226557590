import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='355.16,1.72 355.16,58.78 321.08,58.78 321.08,134.37 272.63,134.37 272.63,188.21 
		193.57,188.21 193.57,250.78 0.25,250.78 0.25,151.13 25.07,151.13 25.07,129.59 94.46,129.59 94.46,24.03 166.95,24.03 
		166.95,8.36 212.35,8.36 212.35,7.42 252.5,7.42 252.5,1.72'
			/>
			<polygon
				className={SCSS.Balcony}
				id='Balcony'
				points='265.33,278.11 265.33,248.49 266.71,248.49 266.71,188.21 193.57,188.21 193.57,269.33 
		191.54,269.33 191.54,278.11'
			/>
		</g>
	);
};

export default Backdrop;
