import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	pan: '0deg',
	hotspot: {
		x: 300,
		y: 35,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.1704134259528982,
		longitude: 0.0005978205739554099,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.27344440555692184,
				longitude: 4.591424663182437,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.11520217122088905,
					longitude: 4.730341728697377,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6790261523276877,
				longitude: 4.37750893052964,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.05754859418757263,
					longitude: 3.898485971746024,
				},
			},
		},
	],
};

export default MainBath;
