import IImage from './IImage';
import Scheme_1_MainBath from './The_Grand_Unit_F-Scheme_01-MainBath.jpg';
import Scheme_1_Bedroom from './The_Grand_Unit_F-Scheme_01-Bedroom.jpg';
import Scheme_1_Kitchen from './The_Grand_Unit_F-Scheme_01-Kitchen.jpg';
import Scheme_1_Living from './The_Grand_Unit_F-Scheme_01-Living.jpg';
import Scheme_1_MasterBedroom from './The_Grand_Unit_F-Scheme_01-MasterBedroom.jpg';
import Scheme_1_Ensuite from './The_Grand_Unit_F-Scheme_01-Ensuite.jpg';

import Scheme_2_MainBath from './The_Grand_Unit_F-Scheme_02-MainBath.jpg';
import Scheme_2_Bedroom from './The_Grand_Unit_F-Scheme_02-Bedroom.jpg';
import Scheme_2_Kitchen from './The_Grand_Unit_F-Scheme_02-Kitchen.jpg';
import Scheme_2_Living from './The_Grand_Unit_F-Scheme_02-Living.jpg';
import Scheme_2_MasterBedroom from './The_Grand_Unit_F-Scheme_02-MasterBedroom.jpg';
import Scheme_2_Ensuite from './The_Grand_Unit_F-Scheme_02-Ensuite.jpg';

export const Scheme1: IImage = {
	Bedroom: Scheme_1_Bedroom,
	Kitchen: Scheme_1_Kitchen,
	Living: Scheme_1_Living,
	MainBath: Scheme_1_MainBath,
	MasterBedroom: Scheme_1_MasterBedroom,
	Ensuite: Scheme_1_Ensuite,
};

export const Scheme2: IImage = {
	Bedroom: Scheme_2_Bedroom,
	Kitchen: Scheme_2_Kitchen,
	Living: Scheme_2_Living,
	MainBath: Scheme_2_MainBath,
	MasterBedroom: Scheme_2_MasterBedroom,
	Ensuite: Scheme_2_Ensuite,
};
