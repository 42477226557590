import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '180deg',
	hotspot: {
		x: 50,
		y: 165,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.021606639653838577,
		longitude: 3.136084081797604,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.280406042204449,
				longitude: 4.593787070636296,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.03846548558393792,
					longitude: 4.580239189093503,
				},
			},
		},
	],
};

export default Bedroom;
