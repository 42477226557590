import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='106.72' y1='129.89' x2='106.72' y2='128.39' />
			<line x1='106.72' y1='126.47' x2='106.72' y2='79.45' />
			<line x1='106.72' y1='78.49' x2='106.72' y2='76.99' />
			<line x1='115.72' y1='111.14' x2='114.22' y2='111.14' />
			<line x1='111.85' y1='111.14' x2='101.16' y2='111.14' />
			<polyline points='99.97,111.14 98.47,111.14 98.47,112.64' />
			<line x1='98.47' y1='114.4' x2='98.47' y2='126.75' />
			<polyline points='98.47,127.64 98.47,129.14 99.97,129.14' />
			<line x1='102.35' y1='129.14' x2='113.03' y2='129.14' />
			<line x1='114.22' y1='129.14' x2='115.72' y2='129.14' />
			<line x1='97.72' y1='76.99' x2='99.22' y2='76.99' />
			<line x1='100.97' y1='76.99' x2='108.85' y2='76.99' />
			<polyline points='109.72,76.99 111.22,76.99 111.22,75.49' />
			<line x1='111.22' y1='73.65' x2='111.22' y2='56.16' />
			<polyline points='111.22,55.24 111.22,53.74 109.72,53.74' />
			<line x1='107.97' y1='53.74' x2='100.1' y2='53.74' />
			<line x1='99.22' y1='53.74' x2='97.72' y2='53.74' />
			<line x1='106.72' y1='53.74' x2='106.72' y2='52.24' />
			<line x1='106.72' y1='50.54' x2='106.72' y2='38.67' />
			<polyline points='106.72,37.82 106.72,36.32 108.22,36.32' />
			<line x1='110.09' y1='36.32' x2='141.79' y2='36.32' />
			<line x1='142.73' y1='36.32' x2='144.23' y2='36.32' />
			<line x1='28.3' y1='140.27' x2='29.8' y2='140.27' />
			<line x1='31.76' y1='140.27' x2='65.12' y2='140.27' />
			<line x1='66.1' y1='140.27' x2='67.6' y2='140.27' />
			<line x1='191.18' y1='80.14' x2='192.68' y2='80.14' />
			<line x1='194.66' y1='80.14' x2='233.29' y2='80.14' />
			<line x1='234.29' y1='80.14' x2='235.79' y2='80.14' />
			<line x1='210.49' y1='35.41' x2='210.49' y2='33.91' />
			<line x1='210.49' y1='32.02' x2='210.49' y2='14.06' />
			<line x1='210.49' y1='13.11' x2='210.49' y2='11.61' />
		</g>
	);
};

export default Dashed;
