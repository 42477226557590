import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polygon
				points='166.95,8.36 166.95,24.03 94.46,24.03 94.46,129.59 25.07,129.59 25.07,151.14 3.65,151.13 
	3.65,247.41 22.25,247.41 22.25,245.11 6.1,245.11 6.1,154.38 29.95,154.38 29.95,150.83 28.3,150.83 28.3,132.82 67.6,132.82 
	67.6,150.83 65.98,150.83 65.98,154.32 71.09,154.32 71.09,132.82 94.32,132.82 94.32,135.73 97.69,135.73 97.69,27.26 
	166.73,27.26 166.73,40.67 172.28,40.67 172.28,37.18 170.21,37.18 170.21,11.61 197.73,11.61 197.73,37.18 194.78,37.18 
	194.78,40.67 221.97,40.67 221.97,32.4 218.49,32.4 218.49,35.41 202.99,35.41 202.99,11.61 218.49,11.61 218.49,14.39 
	221.97,14.39 221.97,11.61 222.33,11.61 223.8,11.61 223.8,7.42 216.45,7.42 216.45,7.42 212.32,7.42 212.35,8.36 170.18,8.36
	'
			/>
			<polygon
				points='70.97,245.11 70.97,247.41 84.85,247.41 84.85,247.41 95.89,247.41 95.89,247.41 118.21,247.41 
	118.21,245.11 97.81,245.11 97.81,161.27 94.32,161.27 94.32,214.76 82.93,214.76 82.93,245.11'
			/>
			<polygon
				points='166.99,247.56 166.99,245.11 190.13,245.11 190.13,248.48 193.57,248.48 193.57,268.94 
	190.33,268.94 166.99,268.94'
			/>
			<polygon
				points='187.68,188.33 190.13,188.33 190.13,184.96 214.32,184.96 214.32,182.51 191.18,182.51 191.18,92.9 
	196.74,92.9 196.74,89.42 191.18,89.42 191.18,72.64 235.79,72.64 235.79,89.42 232.76,89.42 232.76,92.9 239.27,92.9 
	239.27,72.64 241.64,72.64 241.64,69.16 187.69,69.16'
			/>
			<polyline
				points='269.39,182.58 266.71,182.58 266.71,248.48 263.25,248.49 263.25,259.79 266.71,259.79 
	272.63,259.79 272.63,134.37 321.08,134.37 321.08,58.78 355.16,58.78 355.16,1.72 252.5,1.72 252.5,7.42 250.82,7.42 
	250.82,11.55 256.64,11.55 256.64,3.55 269.39,3.55 269.39,24.31 272.87,24.31 272.87,6.97 351.92,6.97 351.92,52.06 
	346.02,52.06 346.02,54.28 334.21,54.28 334.21,52.06 272.87,52.06 272.87,49.85 269.39,49.85 269.39,69.16 267.18,69.16 
	267.18,72.64 269.39,72.64 269.39,98.57 272.87,98.57 272.87,57.32 289.47,57.32 289.47,55.1 301.28,55.1 301.28,57.32 
	317.85,57.32 317.85,131.14 272.87,131.14 272.87,124.11 269.39,124.11 269.39,182.58'
			/>
		</g>
	);
};
export default Walls;
