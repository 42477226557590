import {useEffect, useRef} from '@wordpress/element';
import anime from 'animejs';
import SVG from './SVG';
import SCSS from './Floorplan.module.scss';

const Floorplan = () => {
	const plan = useRef(null);
	useEffect(() => {
		anime({
			targets: plan.current,
			scale: [0, 1],
			duration: 1000,
		});

		return () => {
			console.log('clean');
		};
	}, []);
	return (
		<div
			ref={plan}
			className={SCSS.Floorplan}
			onClick={(event: React.FormEvent) => {
				console.log(event);
			}}>
			<SVG />
		</div>
	);
};

export default Floorplan;
